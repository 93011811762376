.paper {
  @apply my-4 py-4 bg-white;
  border-radius: 10px;

  .data {
    @apply px-5 mt-3 max-h-[520px] overflow-auto;
    &.filter {
      max-height: calc(100vh - 400px);
    }

    table {
      tbody > tr > td {
        border-bottom: 1px solid #EFEFEF;
      }

      tbody {
        tr:last-child {
          td {
            @apply border-0;
          }
        }
      }

      thead {
        @apply sticky z-[5] top-0;
      }

      td {
        vertical-align: middle;
      }

      div[class~="MuiAvatarGroup-root"] {
        @apply mr-2 items-center;
        div[class~="MuiAvatar-root"] {
          width: 33px;
          height: 33px;
          font-size: 14px;

          &[class~="text"] {
            @apply bg-primary-800 text-white;
          }
        }
      }
    }
  }
}

.keyInsights {
  @apply relative mt-5;
  .container {
    @apply relative bg-white rounded-[10px] py-6 px-5 mt-4 ;
    .block {
      @apply bg-secondary-50 w-[350px] inline-block rounded-[10px] p-2.5 mr-3 whitespace-normal;
    }
  }

  .moreAction {
    @apply absolute rounded-[10px] w-[80px] top-0 bottom-0 z-10;
    &.right {
      @apply right-0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 111.11%);
    }

    &.left {
      @apply left-0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 111.11%);
    }

    .arrow {
      @apply flex items-center justify-center h-full;
      svg {
        @apply bg-secondary-700 text-white rounded-full h-8 w-8 cursor-pointer;
      }
    }
  }
}

.horizontalScroll {
  @apply relative;
  .moreAction {
    @apply absolute rounded-[10px] w-[60px] top-0 bottom-0 z-10;
    &.right {
      @apply right-0;
      background: linear-gradient(270deg, var(--primary-100) 0%, rgba(255, 255, 255, 0) 111.11%);
    }

    &.left {
      @apply left-0;
      background: linear-gradient(90deg, var(--primary-100) 0%, rgba(255, 255, 255, 0) 111.11%);
    }

    .arrow {
      @apply flex items-center justify-center h-full;
      svg {
        @apply bg-secondary-700 text-white rounded-full h-8 w-8 cursor-pointer;
      }
    }
  }
}


.gallery {
  position: relative;
  @apply cursor-pointer;

  .next, .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    svg {
      @apply bg-white rounded-full h-8 w-8 cursor-pointer;
      vertical-align: top;
    }
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}


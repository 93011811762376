@import 'src/styles/colors.scss';
@import 'src/styles/shadows.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: overlay;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background;
  @apply text-body;
}

* {
  font-family: 'Open Sans', sans-serif !important;
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  @apply bg-white;
  @apply py-2.5;
  @apply px-1;
  @apply sm:px-12 drop-shadow sm:drop-shadow-none;

  .logo {
    min-width: 122px;

    img {
      @apply h-8 sm:h-11;
      display: inline-block;
      @apply hlg:h-8;
    }

    .logo-img {
      min-width: 122px;
    }
  }

  .menu {
    @apply lg:hidden;
  }
}

.flex-align-item-center {
  align-items: center;
}

.insight-text {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}

.flex-align-center {
  justify-content: center !important;
}

.flex-align-end {
  justify-content: flex-end !important;
}

.text-camel-case {
  text-transform: capitalize;
}

.align-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background: #fff;
}

.text-grey {
  color: #666 !important;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-full {
  border-radius: 100%;
}

.text-align-center {
  text-align: center;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.br-0 {
  border-right: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.bg-transparent {
  background: transparent !important;
}

.menu-border {
  border-bottom: 1px solid #EADFDF  ;
}

.flex-wrap {
  flex-wrap: wrap;
}

.clip-path {
  height: 525px;
  @apply hlg:h-96;
  clip-path: polygon(0 0, 100% 0%, 100% 50%, 0 100%);
  @apply w-full;
  @apply absolute;
  z-index: -1;

  &.primary {
    background: linear-gradient(88.04deg,
        var(--primary-500) 27.58%,
        var(--secondary-300) 51.58%,
        var(--tertiary-400) 93.45%);
  }

  &.opacity {
    opacity: 30%;
    @apply fixed top-0;
    height: 585px;

    &.absoluteEl {
      @apply absolute;
    }
  }
}

footer {
  background: linear-gradient(90deg,
      var(--primary-700) 1.53%,
      var(--secondary-700) 101.83%,
      var(--secondary-600) 101.84%);
  @apply p-2.5;
  @apply sm:px-8;
  z-index: 99;

  h6 {
    @apply xs:text-[15px] text-white;
    display: inline-block;
    @apply pr-6;
  }

  svg {
    @apply align-sub;
    @apply mr-3;
  }
}

table.table {
  width: 100%;
  font-size: 14px;

  th,
  td {
    text-align: left;
    @apply p-2.5;
    //white-space: nowrap;
  }

  td {
    @apply py-4 align-top;

    a.link {
      @apply bg-background;
    }
  }

  thead {
    &.rounded {
      th:first-child {
        @apply rounded-l-md;
      }

      th:last-child {
        @apply rounded-r-md;
      }
    }

    th {
      @apply font-semibold;
    }

    &.white {
      @apply bg-white text-headings;
    }

    &.background {
      @apply bg-background text-headings;
    }
  }

  &.bordered {
    tbody>tr>td {
      border-bottom: 1px solid #efefef;
    }
  }

  &.middle-aligned {
    tbody>tr>td {
      vertical-align: middle;
    }
  }
}

.expand-column {
  padding-top: 0;
}

.expanded-table a.link {
  background-color: transparent !important;
}

.expanded-row-section {
  &>div {
    padding: 0;
    margin: 0;
  }
}

.expandable-comp {
  margin-top: 1rem;
  padding-top: 1rem;
  box-shadow: none !important;
  border-radius: 10px !important;

  & .company-selector {
    &>div {
      &>div {
        height: 40px;
      }
    }
  }
}

.card {
  border-radius: 10px;
  @apply bg-white;
}

.chip {
  @apply py-1.5 px-3 bg-surface-overlay border rounded-[16px] text-[14px] border-shadow inline-block text-headings cursor-pointer capitalize;
  letter-spacing: 0.25px;

  &.active {
    @apply bg-primary-500 border-primary-500 text-white;
  }

  &.disabled {
    @apply text-disabled cursor-not-allowed;
  }
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--body);
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--body);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-grey1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary-500;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-500;
  width: 8px;
}

:root {
  scrollbar-color: var(--primary-500) transparent !important;
  scrollbar-width: thin !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#sidebarContainer {
  overflow: auto;
  height: 100%;
}

#sidebarContainer::-webkit-scrollbar {
  width: 6px;
}

#sidebarContainer::-webkit-scrollbar-thumb {
  @apply bg-white;
}

#sidebarContainer::-webkit-scrollbar-track {
  margin-bottom: 26px;
}

.carousel-root {
  &.moreInsights {
    @apply rounded-[10px] bg-background;

    .carousel {
      .thumbs-wrapper.axis-vertical {
        @apply my-0 mx-1.5 rounded-b-[10px] visible;

        .thumb.selected,
        .thumb:hover {
          @apply border-grey2;
        }
      }
    }

    .imageCard {
      .content {
        @apply rounded-t-[10px] overflow-hidden;
        @apply m-2.5 rounded-[10px];
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

.query-btn {
  font-size: 14px;

  .icon {
    width: 19px;
    height: 19px;
  }
}

.blocker-desc {
  max-height: 50px;
  overflow-y: auto;
}

.vul-reference {
  max-height: 50px;
  overflow-y: auto;
}

.app-notifications {
  .notification-item:hover {
    background: #f6f9fc;
    border-radius: 6px;
    cursor: pointer;
  }
}

.fz-25-lh-34 {
  font-size: 25px;
  line-height: 34px;
}

.filter-btn {
  background-color: var(--primary-100) !important;
  color: var(--primary-800) !important;
  font-size: 15px;

  &>svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.PrivateSwipeArea-root {
  display: none;
}

.hide-filter {
  display: none;
}

.filter-component {
  background-color: var(--primary-50);
  border-radius: 10px;
  padding: 15px 20px;

  .filter-options {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    flex-wrap: wrap;

    .box-indicator {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid var(--primary-50);
      position: absolute;
      top: -29px;
      right: 35px;
    }

    .filter {
      flex-basis: auto;
      min-height: 121px;
      border-right: 1px solid var(--grey2);
      padding-right: 25px;
      margin-right: 35px;
      flex-wrap: wrap;

      &.last-filter {
        border: none;
        margin-right: 0;
        padding: 0;
      }

      .name {
        color: var(--grey3);
        font-weight: 600;
        text-transform: capitalize;
      }

      .option {
        ul {
          li {
            display: inline-block;
            padding: 5px 12px;
            color: var(--headings);
            background: var(--surface-overlay);
            margin-right: 15px;
            margin-bottom: 15px;
            border-radius: 30px;
            border: 1px solid var(--border);
            text-transform: capitalize;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: var(--white);
              background: var(--functional-blue-600);
            }

            &.active {
              color: var(--white);
              background: var(--functional-blue-600);
            }
          }
        }

        .filter-message {
          font-size: 12px;
          background: #12afb820;
          padding: 12px;
          border-radius: 10px;
          margin-bottom: 9px;
        }
      }

      .date {
        display: flex;

        .from-date,
        .to-date {
          padding: 0;
          width: 180px;

          &>div {
            margin-top: 0;

            input {
              height: 35px !important;
              padding: 0 8px;
            }
          }
        }

        &>div {
          flex: auto;
          padding: 5px 10px 0;
        }

        .range {
          display: flex;

          .from-range,
          .to-range {
            padding: 0;
            width: 180px;

            &>div {
              margin-top: 0;

              input {
                height: 35px !important;
                padding: 0 8px;
              }
            }
          }
        }

        &>div {
          flex: auto;
          padding: 5px 10px 0;
        }
      }
    }
  }

  .dropdown {
    display: flex;

    .init-dropdown {
      padding: 0;
      height: 54px;
      width: 370px;

      &>div {
        margin-top: 0;

        input {
          height: 35px !important;
          padding: 0 8px;
        }
      }
    }

    &>div {
      flex: auto;
      padding: 5px 10px 0;
    }
  }

  .root-text {
    background: var(--primary-100);
    border-radius: 14px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    float: left;
    clear: left;
    min-width: 50px;
    padding: 4px 8px 4px 8px;
    margin: 0px 4px;
  }

  .row {
    display: flex;
  }

  .filter-actions {
    text-align: right;
    padding-top: 15px;
    border-top: 1px solid var(--grey2);
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 100;
    margin-left: -10px;
    margin-top: 10px;

    .react-datepicker__navigation {
      margin-top: 7px;
    }
  }
}

.bloker-status-item:hover {
  background: #f6f9fc;
  border-radius: 6px;
  cursor: pointer;
}

.disclr-sec {
  background-color: #f6f9fc;
  color: #5f6162;
}

.disclr-sec-prim {
  background-color: var(--primary-50);
  color: #5f6162;
}

.chat-sub-title {
  font-size: 0.95rem;
}

.disclr-sec-def-pos {
  top: 140px;
}

.disclr-sec-second-pos {
  top: 25px;
}

.chatarea {
  top: 130px;
}

.desc-list-marker {
  &::marker {
    color: var(--secondary-700);
    font-size: 22px;
  }
}

.user-details-side {
  padding-left: 10px;
  height: calc(100vh - 72px);
  position: relative;

  .user-details {
    display: flex;

    .company-name {
      text-transform: capitalize;
    }

    .profile-pic {
      flex-basis: 84px;
      height: 84px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      background-color: var(--primary-500);

      &>div {
        font-size: 34px;
        color: var(--white);
        font-weight: 700;
      }
    }

    .details {
      flex: 1;
    }
  }

  .role-details {
    margin-top: 40px;

    .current-role-info {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .current-role {
      &>p {
        color: var(--subtitle);
        font-size: 20px;
        margin-bottom: 15px;
      }

      &>.role {
        &>ul {
          &>li {
            display: inline-block;
            padding: 5px 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 16px;
            color: var(--subtitle);
            background-color: var(--border);
            border: 1px solid var(--border);

            &.current {
              color: var(--white);
              background-color: var(--primary-500);
            }
          }
        }
      }
    }
  }

  .invite-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    &>.cancel-invite {
      margin-right: 15px;
    }
  }
}

.user-list {
  .user-div {
    text-transform: capitalize;
  }
}

.filter-options {
  .indiv-dropdown {
    z-index: 0;
  }
}

.add-users {
  height: 70vh;

  &>.add-tab {
    min-height: 60vh;
    overflow-y: auto;

    &>.customer-form {
      height: 60vh;
    }

    &>.siemba-form {
      height: 60vh;
    }
  }
}

.invite-users {
  height: 70vh;

  &>.invite-form {
    min-height: 60vh;
  }
}

//annotation for reports
.annotationLayer {
  &>.linkAnnotation>a:hover {
    background: none !important;
    box-shadow: none;
    opacity: 0 !important;
  }
}

.search-select-items {
  padding: 14px !important;

  &.selected {
    background-color: var(--primary-50);
  }

  &>div {
    width: 100%;

    &>p {
      width: inherit;

      &>span {
        white-space: break-spaces;
      }
    }
  }
}

.update-asset-stage {
  & ul.sub-stage {
    display: flex;
    position: relative;
    margin-top: 20px;

    &>hr {
      border: none;
      width: 190px;
      background: var(--primary-500);
      position: absolute;
      left: 30px;
      top: 18px;
      height: 2px;
      z-index: 0;

      &.second-divider {
        left: 230px;
      }

      &.disabled {
        background: var(--grey1) !important;
      }
    }

    &>li {
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 120px;
      z-index: 1;

      &>.level-name {
        font-size: 12px;
        margin-top: 5px;
        font-weight: 600;

        &.disabled {
          color: var(--subtitle);
        }
      }

      &>.level-no {
        width: 36px;
        display: flex;
        height: 36px;
        background: var(--primary-500);
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: 600;

        &.disabled {
          background: var(--grey1) !important;
        }
      }
    }
  }
}

.download-link {
  color: inherit;
  text-decoration: none;
}

.download-link:hover .download-import-template {
  color: gray;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.25px;
}

.download-import-template {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.25px;
}

.download-text:hover {
  color: gray;
}

.background-shade-import-assets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 17vh;

  .upload-button {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.25px;
  }
}

.background-shade-upload-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;

  .upload-button {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: var(--primary-800);
    text-decoration: underline;
  }
}

.background-shade-upload-more-media {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: 0px 20px 0px 20px;

  .upload-button {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.25px;
    color: var(--primary-800);
    text-decoration: underline;
  }
}

.update-auto-interation {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}

.note {
  background: var(--background);
  padding: 25px 16px;
  font-weight: 600;
  border-radius: 8px;
}

.current-start-date {
  padding: 10px 0 10px;

  &>span {
    font-weight: bold;
  }
}

.pentest-iteration-history {
  margin-top: 20px;

  &>.history-toggle {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: var(--body);

    &>svg {
      display: inline-block;
      margin-left: 5px;
      margin-top: 4px;
      cursor: pointer;

      &.up {
        transform: rotate(180deg);
      }
    }

    &+div {
      padding: 0;
      margin: 0;
      max-height: 260px;
      border-radius: 0;

      & .iteration-history-table {
        padding: 0;
        margin: 0;
        height: 240px;
      }
    }
  }
}

.justification-error {
  text-align: left;
  font-size: 12px;
  margin: 3px 10px 0px;
  color: #f65c5c;
}

.expandable-table-header {
  top: -16px !important;
}

#count-stroke {
  color: var(--primary-500);
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke: 1px var(--primary-500);
}

.gpt-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.gpt-loader::before,
.gpt-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid var(--functional-blue);
  animation: prixClipFix 2s linear infinite;
}

.gpt-loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--functional-orange);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}

/* HTML: <div class="loader"></div> */
.gpt-loader-activity {
  width: 50px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.progress {
  transition: width 0.3s ease;
}

.th-align-center {
  &>div {
    /* Apply styles to the child <div> */
    @apply justify-center;
  }
}

.btn-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.btn-loader::before,
.btn-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid white;
  animation: prixClipFix 2s linear infinite;
}

.btn-loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--functional-orange);
}

.collapse-sec {
  margin-top: 15px;
}

.vuln-assessment-dashboard {

  & .padding-0 {
    padding: 0 !important;
  }

  & .overview-section {
    display: flex;

    &>.overview {
      flex: 1;
      margin-right: 10px;

      & .gauge-graph {
        margin-top: 0 !important;
        padding: 15px;
      }

      & .overview-content {
        position: relative;
        padding: 20px;
      }

      & .overview-values {
        margin-top: 15px;
        margin-bottom: 20px;

        &>span {
          margin-right: 20px;
        }
      }

      & .scan-details {
        margin-top: 20px;

        &>div {
          display: inline-block;
          text-align: center;
          border-right: 1px solid #DADADA;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          font-weight: 600;

          &.option-profile {
            padding-left: 0;

            &>span {
              color: #228EBC;
              margin-left: 5px;
            }
          }

          &.total-hosts {
            &>span {
              &>span {
                color: #228EBC;
                margin-right: 5px;
              }
            }
          }

          &.active-hosts-count {
            &>span {
              &>span {
                color: #3ADA7A;
                margin-right: 5px;
              }
            }
          }

          &.inactive-hosts-count {
            &>span {
              &>span {
                color: #F65C5C;
                margin-right: 5px;
              }
            }
          }
        }
      }

      & .overview-values-lower-sec {
        margin-bottom: 0;
      }
    }

    &>.risk-level {
      flex-basis: 300px;
    }
  }

  & .insights {
    margin-top: 15px;
  }

  & .open-findings-count {
    margin-top: 15px;

    &>.content {
      display: flex;
      margin-top: 15px;

      &>div {
        flex: 1;
      }
    }


    & .open-findings-overview {
      background: #fff;
      margin-right: 15px;
      border-radius: 10px;
      position: relative;

      & .overlay {
        position: absolute;
        width: 100%;
        height: 144px;
      }

      & .total-findings {
        height: 200px;
        text-align: center;
        border-radius: 10px;
        padding-top: 18px;
        padding-left: 25px;
        padding-right: 25px;
        cursor: pointer;

        &>.count {
          font-size: 38px;
          font-weight: 700;
          display: inline-block;
        }

        &>.type {
          font-size: 20px;
          font-weight: 600;
        }

        &.confirmed-findings {
          background: rgb(246 92 92 / 30%);
        }

        &.potential-findings {
          background: rgb(137 215 234 / 30%);
        }

        &.information-gathering {
          background: rgb(131 224 225 / 30%);
        }
      }

      & .findings-count-breakdown {
        padding: 15px;

        &>.content {
          padding: 15px;
          border: 1px solid #F1F1F2;
          border-radius: 10px;
          box-shadow: 0px 3px 4px 0px #00000008;
          margin-top: -70px;
          background: #fff;
          padding-bottom: 5px;

          &>.urgent {
            &>.label {
              color: #9A2335;

              &>span {
                background: #9A2335;
              }
            }
          }

          &>.critical {
            &>.label {
              color: #CC394F;

              &>span {
                background: #CC394F;
              }
            }
          }

          &>.serious {
            &>.label {
              color: #FE7754;

              &>span {
                background: #FE7754;
              }
            }
          }

          &>.medium {
            &>.label {
              color: #FFE765;

              &>span {
                background: #FFE765;
              }
            }
          }

          &>.minimum {
            &>.label {
              color: #CEDCE9;

              &>span {
                background: #CEDCE9;
              }
            }
          }

          &>div {
            display: flex;
            margin-bottom: 10px;

            &>div {
              flex: 1;
            }
          }

          & .label {
            font-weight: 700;

            &>span {
              height: 10px;
              width: 20px;
              display: inline-block;
              margin-right: 10px;
              border-radius: 10px;
            }
          }

          & .value {
            text-align: right;
            font-weight: 700;
          }
        }
      }
    }

  }

  & .asset-statistics {
    margin-top: 15px;

    &>.content {
      display: flex;

      &>div {
        flex: 1;
        margin-right: 12px;
      }
    }
  }
}

.overview-section {
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  position: relative;

  &.p-0 {
    padding: 0 !important;
  }

  &.vuln-assessment-overview {
    background: #B3ECEC;

    &>h5 {
      height: calc(100% - 20px);
      padding: 50px 0;
    }
  }

  &.pentest-overview {
    background: #B6E7F2;

    &>h5 {
      margin-top: 35px;
    }

    &>h6 {
      margin-top: 15px;
    }
  }

  &>span {
    font-size: 14px;
    font-weight: 600;
  }
}

.aggregated-asset-risk-level {
  background: #fff;
  border-radius: 8px;
  padding-top: 10px;

  &>div {
    &>p {
      margin-left: 10px;
    }
  }
}

.VA-findings-severity-chart {
  &>div {
    height: 100%;

    &>div {
      height: 200px;
      padding: 20px;

      &>div {
        // display: flex;
        align-items: center;
      }
    }
  }
}

.go-to-dashboard {
  background: #E0F8F7;
  border-radius: 8px;
  margin-top: 45px;

  & .go-to-btn {
    padding-left: 20px;
  }

  & .dashboard-icon {
    display: flex;
    justify-content: flex-end;
    margin-right: -10px;
  }

  & h5 {
    margin-top: 20px;
    padding-left: 20px;
    margin-bottom: 5px;
    color: #12AFB8;
  }

  & span {
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 12px;
    display: inline-block;
  }

  & svg {
    margin-left: -12px;
  }
}

.pentest-dashboard {
  background: #E2F6FA;

  & .go-to-btn {
    &>button {
      background-color: #228EBC;
    }
  }

  & h5 {
    margin-top: 20px;
    padding-left: 20px;
    margin-bottom: 5px;
    color: #228EBC;
  }
}

.bar-chart {
  background: #fff;
  border-radius: 8px;
  padding: 15px 20px;

  &>label {
    font-weight: 600;
    color: #000000DE;
    font-size: 16px;
    margin-bottom: 35px;
  }

  &>p {
    font-size: 12px;
    color: #00000099;
    font-weight: 600;
    margin-top: 5px;
  }

  &>div {
    margin-top: 35px;
  }
}

.sheild-icon {
  transform: scale(1.25);
}

.functional-red {
  color: #F65C5C;
}

.primary-color {
  color: #12AFB8 !important;
}

.primary-background-color {
  background: #12AFB8;
}

.secondary-color {
  color: #228EBC;
}

.high-red {
  color: #9A2335;
}

.confirmed-red {
  color: #CC394F;
}

.align-items-right {
  justify-content: flex-end !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.qualys-insight-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  &>span {
    flex: 1
  }
}

.qualys-insight-title {
  display: flex;
  flex: none !important;
  width: 65px;
  height: 65px;
  background: #fff;
  border-radius: 78px;
  margin-right: 15px;
  color: #228EBC;
  justify-content: center;
  align-items: center;
}

.qualys-insight-desc {
  padding: 20px 120px 0;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.file-loading {
  display: flex;

  &>img {
    margin-right: 5px;
    animation: spin 2s linear infinite;
  }
}

.tile-container {
  display: flex;
  flex-wrap: wrap;

  &>.tile-content {
    padding: 6px 10px;
  }
}

.scan-title {
  white-space: pre;
  text-wrap: balance !important;
}

.tab-layout {
  display: flex;

  &>div {
    flex: 1;

    &.last-scan-date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmed-box {
  background: rgba(252, 206, 206, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .confirmed-text {
    color: #F65C5C;
    font-weight: 700;
  }
}

.potential-box {
  background: rgba(220, 243, 249, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .potential-text {
    color: #228EBC;
    font-weight: 700;
  }
}

.ig-box {
  background: rgba(218, 246, 246, 1);

  & .ig-text {
    color: #228EBC;
    font-weight: 700;
  }
}

.active-hosts {
  background: rgba(216, 248, 228, 1);
}

.active-tile {
  background: rgba(216, 248, 228, 1);
}

.inactive-hosts {
  background: rgba(253, 222, 222, 1);
}

.inactive-tile {
  background: rgba(253, 222, 222, 1);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.gauge-container {
  flex-direction: column;

  &>.gauge-graph {
    background: #fff;
    border-radius: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 15px;
    position: relative;

    &[size="176"] {
      align-items: center;

      .gauge {
        height: 176px;
        width: 176px;
      }

      &>.gauge-outer-line {
        width: 211px;
        height: 211px;
      }
    }

    &[size="140"] {
      padding: 0 !important;

      .gauge {
        height: 140px;
        width: 140px;
      }

      &>.gauge-outer-line {
        width: 175px;
        height: 175px;
      }

      &>.value-display {
        margin-top: 75px;
        margin-left: 5px;

        &>.value {
          font-size: 24px;
          line-height: 14px;
        }

        &>.label {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }

    &[size="100"] {
      .gauge {
        height: 100px;
        width: 100px;
      }

      &>.gauge-outer-line {
        width: 135px;
        height: 135px;
      }

      &>.value-display {
        margin-top: 75px;
      }
    }

    &>.gauge-outer-line {
      display: inline-block;
      border: 3px solid #F6F9FC;
      padding: 15px;
      border-radius: 100%;
      border-bottom: none;
      position: relative;

      .gauge {
        &>circle {
          stroke-width: 8px;
          fill: transparent;
        }
      }

      &>.center-point {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -17px;
        margin-left: -17px;
      }

      &>.indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: 0px;
        transform-origin: top left;
        transition: transform 1s;
        // animation: indicatorRotate 2s linear infinite;

        &[value="01"] {
          transform: rotate(105deg);
        }

        &[value="02"] {
          transform: rotate(145deg);
        }

        &[value="03"] {
          transform: rotate(170deg);
        }

        &[value="04"] {
          transform: rotate(200deg);
        }

        &[value="05"] {
          transform: rotate(230deg);
        }

        &[value="06"] {
          transform: rotate(270deg);
        }

        &[value="07"] {
          transform: rotate(300deg);
          margin-top: -2px;
        }

        &[value="08"] {
          transform: rotate(330deg);
          margin-top: -2px;
        }

        &[value="09"] {
          transform: rotate(350deg);
          margin-top: -2px;
        }

        &[value="10"] {
          transform: rotate(380deg);
          margin-top: -2px;
        }
      }

      &>.value-indicators {
        background: #fff;
        padding: 0 0 10px;
        width: 100%;
        display: flex;
        position: relative;

        &>.low-side {
          display: inline-block;
          width: 36px;
          height: 20px;
          border-radius: 6px;
          background: #CFE2F3;
          color: #000;
          padding: 1px 11px;
          font-size: 12px;
          margin: -13px 4px;
        }

        &>.high-side {
          position: absolute;
          right: 0;
          display: inline-block;
          width: 36px;
          height: 20px;
          border-radius: 6px;
          background: #9A2335;
          color: #fff;
          padding: 1px 11px;
          font-size: 12px;
          margin: -15px -2px;
        }
      }

    }

    &>.value-display {
      position: absolute;
      width: 100%;
      transform: translateY(53px);
      text-align: center;
      font-weight: 700;

      &[value="01"],
      &[value="02"] {
        color: #333;
      }

      &[value="03"],
      &[value="04"] {
        color: #FFE765;
      }

      &[value="05"],
      &[value="06"] {
        color: #FE7754;
      }

      &[value="07"],
      &[value="08"] {
        color: #CC394F;
      }

      &[value="09"],
      &[value="10"] {
        color: #9A2335;
      }

      &>.value {
        font-size: 28px;
        line-height: 28px;
      }

      &>.label {
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  }

  &>.gauge-no-data {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#gray {
  stroke: #CFE2F3;
  stroke-dasharray: 41.5, 284;
  /* length of arc, circumference of circle */
  stroke-dashoffset: -102.75;
  stroke-linecap: round;
}

#yellow {
  stroke: #FFE765;
  stroke-dasharray: 44.5, 284;
  /* length of arc, circumference of circle */
  stroke-dashoffset: -143.75;
  /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
  stroke-linecap: round;
}

#orange {
  stroke: #FE7754;
  stroke-dasharray: 51.5, 284;
  /* length of arc, circumference of circle */
  stroke-dashoffset: -187.75;
  /* offset of arc from start point (1/2 arc length + 1/4 circumference) */
  stroke-linecap: round;
}

#red {
  stroke: #CC394F;
  stroke-dasharray: 44.5, 284;
  /* length of arc, circumference of circle */
  stroke-dashoffset: -238.75;
  /* offset of  arc from start point (1/2 of arc length) */
  stroke-linecap: round;
}

#darkRed {
  stroke: #9A2335;
  stroke-dasharray: 41.5, 284;
  /* length of arc, circumference of circle */
  stroke-dashoffset: -323.75;
  /* offset of arc from start point (1/2 arc length + 3/4 circumference) */
  stroke-linecap: round;
}

.ip-search {
  padding: 0;
  margin: 0 0.50rem 0.50rem;

  &>div {
    &>svg {
      margin-top: 2px;
    }

    &>input {
      width: 100%;
      height: 30px;
      outline: none;
      box-shadow: none;
      padding-left: 7px;
    }
  }
}

.info-message {
  font-size: 12px;
  border-radius: 10px;
  background: #12afb820;
  padding: 10px;
}

.hover-info {
  position: absolute;
  z-index: 10;
  right: 22%;
  top: 22%;
}

@keyframes indicatorRotate {
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes indicatorRotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes indicatorRotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.ctem-dashboard {
  .initial-state {
    height: calc(100vh - 133px);
    background: #fff;
    border-radius: 10px;
    padding: 20px;
  }

  .ctem-top-banner {
    .user-welcome {
      font-size: 18px;
    }

    .last-updated {
      text-align: right;
    }

    & .risk-level {
      color: #228EBC;

      &.critical {
        color: #9a2335;
      }

      &.high {
        color: #cc394f;
      }

      &.moderate {
        color: #fe7754;
      }
    }
  }

  .assets-details {
    box-shadow: 0px 1px 3px 0px #00000033;
    background: #fff;
    border-radius: 15px;

    &>.all-assets-count {
      margin: 15px 0;
      padding: 0 15px;
      border-right: 1px dashed #E1E3EA;
      display: flex;
      align-items: center;

      &>span {
        flex-grow: 1;

        &.label {
          display: flex;
          align-items: center;

          &>span {
            display: inline-block;

            &.label-name {
              display: inline-flex;
              margin-left: 5px;
            }

            &.icon {
              padding: 15px;
              background: #FFF3E0;
              border-radius: 100%;
            }
          }
        }

        &.value {
          flex-basis: 65px;
          flex-grow: unset;

          &>div {
            display: inline-block;
            padding: 15px 20px;
            background: #F6F9FC;
            border-radius: 8px;
            font-size: 20px;
            color: #228EBC;
          }
        }
      }
    }

    &>.risk-score {
      margin: 15px 0;
      padding: 0 15px;
      border-right: 1px dashed #E1E3EA;
      display: flex;

      &>span {
        flex-grow: 1;

        &.label {
          display: flex;
          align-items: center;

          &>span {
            display: inline-block;
          }
        }

        &.value {
          flex-basis: 100px;
          flex-grow: unset;
          padding: 5px 10px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding: 5px 0;
          background: #F6F9FC;
          color: #228EBC;
          font-size: 20px;

          &.minimal {
            font-size: 16px;
            background: #CFE2F3;
            color: #7B7D7F;
          }

          &.low {
            font-size: 16px;
            background: #FEF9DF;
            color: #7B7D7F;
          }

          &.moderate {
            font-size: 16px;
            background: #FFE4DD;
            color: #FE7754;
          }

          &.high {
            font-size: 16px;
            background: #CC394F33;
            color: #CC394F
          }

          &.critical {
            font-size: 16px;
            background: #CC394F33;
            color: #9A2335
          }

          &>div {
            text-align: center;
          }

          & .value-label {
            font-size: 14px;
          }
        }
      }
    }

    &>.total-findings {
      margin: 15px 0;
      padding: 0 15px;
      border-right: 1px dashed #E1E3EA;
      display: flex;

      &>span {
        flex-grow: 1;

        &.label {
          display: flex;
          align-items: center;

          &>span {
            display: inline-block;
          }
        }

        &.value {
          flex-basis: 80px;
          flex-grow: unset;
          padding: 5px 10px;
          background: #F6F9FC;
          color: #228EBC;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;

          &>div {
            text-align: center;
          }
        }
      }
    }

    &>.risk-trend {
      padding-top: 15px;
      padding-left: 15px;
      display: flex;

      &>span {

        &.label {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          &>span {
            display: inline-block;
          }
        }

        &.value {
          width: calc(100% - 94px);
          overflow: hidden;
          border-radius: 13px;

          &>div {
            height: 100%;
          }

          &>div.no-data {
            background: #F6F9FC;
            font-weight: 600;
            height: calc(100% - 20px);
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .risk-posture-cont {
    background: #fff;
    border-radius: 15px;
    padding: 15px;
  }

  .ctem-key-insights {
    // background: #fff;
    // border-radius: 15px;
    // padding: 15px;
  }

  .funnel-cont {
    padding: 20px 30px;
    background: #fff;
    border-radius: 10px;

    & .funnel-dash-border {
      border-bottom: 1px dashed #BFBEBE;
      z-index: 1;
      position: absolute;
      width: calc(100% - 20%);
      left: 10%;
      top: 53px;
    }

    & .heading {
      &>p {
        position: relative;
        z-index: 1;
      }
    }

    .cards {
      display: flex;

      >.scoping {
        &>.ellipse {
          top: -20px;
          right: -70px;
        }
      }

      >.discovery {
        &>.ellipse {
          top: -20px;
          right: -45px;
        }
      }

      >.prioritization {
        &>.ellipse {
          top: 0;
          right: -45px;
        }
      }

      >.validation {
        &>.ellipse {
          top: 15px;
          right: -45px;
        }

        &>.details {
          &>.graph {
            height: 100%;
          }
        }
      }

      >.mobilization {
        &>.details {
          &>.graph {}
        }

        & .status-label {
          flex-basis: 120px;
        }

        & .status-value {
          flex: 1;
        }
      }

      >div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        >.ellipse {
          position: absolute;
          z-index: 0;
        }

        >img {
          z-index: 1;
        }

        >.title {
          background: #12AFB8;
          color: #fff;
          font-weight: 700;
          padding: 2px 20px;
          border-radius: 5px;
          margin-bottom: 15px;
          z-index: 1;
        }

        >.details {
          background: #fff;
          width: 100%;
          z-index: 1;
          margin-top: 20px;
          padding: 10px 10px 40px;
          border-radius: 10px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
          height: 342px;

          &>.title {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
          }
        }
      }
    }
  }

  .risk-posture-cont {
    .all-asset-details {
      display: flex;

      &>div {
        flex: 1 1;
        margin-right: 20px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px #00000024;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      &>.last-asset {
        margin-right: 0;
      }

      .overlay {
        align-items: center;
        background: #f6f9fc;
        border-radius: 10px;
        display: flex;
        /* font-weight: 600; */
        height: 100%;
        justify-content: center;
        left: 0;
        /* opacity: .9; */
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 16px;
        padding: 20px;
        flex-direction: column;

        &>.coming-soon {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          color: #228ebc;
        }

        &>.coming-soon-subtext {
          font-size: 14px;
          text-align: center;
        }
      }

      .asset-details {
        display: flex;
        padding: 20px;
        flex-basis: 100px;

        &>.asset {
          flex: 1;
          display: flex;
          align-items: center;

          &>.icon {
            width: 40px;
            height: 40px;
            display: flex;
          }

          &>.name {
            margin-left: 5px;
            font-weight: 600;
            color: #000000DE;
            flex: 1;

            &>p {
              color: #00000099;
            }
          }
        }

        &>.count {
          flex-basis: 42px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &>span {
            font-size: 24px;
            font-weight: 600;
            color: #228EBC;
          }
        }
      }

      .asset-data {
        padding: 20px 20px 40px;
        box-shadow: 0px 1.5px 4px 0px #00000024;
        border-radius: 10px;
        flex: 1;
      }

      .risk-score {
        border: 0;
        border-bottom: 1px dashed #E1E3EA;
        display: flex;
        padding-bottom: 1.5rem;

        &>.name {
          font-weight: 600;
          display: flex;
          align-items: center;
        }

        &>.value {
          border-radius: 8px;
          padding: 5px 0;
          background: #f6f9fc;
          color: #228EBC;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &.minimal {
            background: #CFE2F3;
            color: #7B7D7F;
          }

          &.low {
            background: #FEF9DF;
            color: #7B7D7F;
          }

          &.moderate {
            background: #FFE4DD;
            color: #FE7754;
          }

          &.high {
            background: #CC394F33;
            color: #CC394F
          }

          &.critical {
            background: #CC394F33;
            color: #9A2335
          }

          &>p {
            text-align: center;

            &.score {
              font-size: 18px;
              font-weight: 700;
            }

            &.label {
              font-size: 16px;
              font-weight: 700;
              color: inherit;
              text-transform: capitalize;
            }
          }
        }

        &>div {
          flex: 1;
        }
      }

      .findings {
        display: flex;

        &>div {
          flex: 1;

          &.name {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }

          &.value {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 60px;
            border-radius: 10px;
            justify-content: center;

            &.empty {
              background: #f6f9fc;
            }

            &>.score {
              font-size: 24px;
              color: #228EBC;
              font-weight: 600;
            }

            &>.percentage {
              font-size: 16px;
              color: #00000099;
              font-weight: 600;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.ctem-insights {
  & .ctem-insight-scrollbar {
    &>div {
      height: initial;

      &>div {
        height: calc(100% - 10px);
      }
    }
  }

  & .priority {
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 10px;
    right: 10px;
    top: -10px;
  }

  & .critical {
    background: #9A2335;
  }

  & .high {
    background: #CC394F;
  }

  & .medium {
    background: #FE7754;
  }

  & .low {
    background: #FFE765;
  }

  & .info {
    background: #CFE2F3;
  }
}

.custom-graph-tooltip {
  border: 1px solid #ececec;
  background: #fff;
  padding: 0px 10px;
}

.area-chart-tooltip {
  opacity: 0.8;
}

.bar-chart-tooltip {
  min-width: 100px;
  max-width: 0;
}

.area-chart-overlay {
  background: linear-gradient(to left, #ffffff70, #fff);
  width: 20px;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.insight-container {
  &>.upper-section {
    display: flex;

    &>div {
      flex: 1;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1411764706);
      border-radius: 10px;
      padding: 20px;
      background: #fff;

      &>p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &>div {
        color: #000;
      }

      &.description {
        margin-right: 20px;
      }

      &.graph {
        padding: 0 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &>.lower-section {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1411764706);
    background: #fff;
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px;

    display: flex;

    &>div {
      flex: 1;
    }
  }
}

.ctem-insight-container {
  background: #f6f9fc;
}

.easm-insight-container {
  background: #f6f9fc;
}

.insight2-bar-chart {
  & .graph-container {
    width: calc(100% - 200px);
  }

  & .label {
    width: 200px;
    display: flex;
  }
}

.prioritized-finding-graph {
  &>.graph-container {}

  &>.label {
    display: flex;
  }
}

.stackedbar-container {
  & .stacked-bar {
    width: 100%;

    & .graph-container {
      width: calc(100% - 180px);
    }

    & .label {
      width: 170px;
      display: flex;
    }
  }
}

.stacked-bar {
  width: 100%;
}

.note-prioritized-finding {
  font-size: 14px;
}

.easm-content {
  & .easm-top-banner {
    & .flash-message {
      background: #228EBC;
      padding: 8px 15px;
      border-radius: 6px;

      &>.message {
        display: flex;
        align-items: center;
        padding: 0 20px;
        color: #fff
      }

      & button {
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
}

.domain-details {
  box-shadow: 0px 1px 3px 0px #00000033;
  background: #fff;
  border-radius: 15px;

  &>.all-assets-count {
    margin: 15px 0;
    padding: 0 15px;
    border-right: 1px dashed #E1E3EA;
    display: flex;
    align-items: center;

    &>span {
      flex-grow: 1;

      &.label {
        display: flex;
        align-items: center;

        &>span {
          display: inline-block;

          &.label-name {
            display: inline-flex;
            margin-left: 5px;
          }

          &.icon {
            padding: 15px;
            background: #FFF3E0;
            border-radius: 100%;
          }
        }
      }

      &.value {
        flex-basis: 65px;
        flex-grow: unset;

        &>div {
          display: inline-block;
          padding: 15px 20px;
          background: #F6F9FC;
          border-radius: 8px;
          font-size: 20px;
          color: #228EBC;
        }
      }
    }
  }

  &>.risk-score {
    margin: 15px 0;
    padding: 0 15px;
    border-right: 1px dashed #E1E3EA;
    display: flex;

    &>span {
      flex-grow: 1;

      &.label {
        display: flex;
        align-items: center;

        &>span {
          display: inline-block;
        }
      }

      &.value {
        flex-basis: 160px;
        flex-grow: unset;
        padding: 5px 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 5px 0;
        background: #F6F9FC;
        color: #228EBC;
        font-size: 20px;

        &.minimal {
          font-size: 16px;
          background: #CFE2F3;
          color: #7B7D7F;
        }

        &.low {
          font-size: 16px;
          background: #FEF9DF;
          color: #7B7D7F;
        }

        &.moderate {
          font-size: 16px;
          background: #FFE4DD;
          color: #FE7754;
        }

        &.high {
          font-size: 16px;
          background: #CC394F33;
          color: #CC394F
        }

        &.critical {
          font-size: 16px;
          background: #CC394F33;
          color: #9A2335
        }

        &>div {
          text-align: center;
        }

        & .value-label {
          font-size: 14px;
        }
      }
    }
  }

  &>.total-findings {
    margin: 15px 0;
    padding: 0 15px;
    border-right: 1px dashed #E1E3EA;
    display: flex;

    &>span {
      flex-grow: 1;

      &.label {
        display: flex;
        align-items: center;

        &>span {
          display: inline-block;
        }
      }

      &.value {
        flex-basis: 80px;
        flex-grow: unset;
        padding: 5px 10px;
        background: #F6F9FC;
        color: #228EBC;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        &>div {
          text-align: center;
        }
      }
    }
  }

  &>.risk-trend {
    padding-top: 15px;
    padding-left: 15px;
    display: flex;

    &>span {

      &.label {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &>span {
          display: inline-block;
        }
      }

      &.value {
        width: calc(100% - 94px);
        overflow: hidden;
        border-radius: 13px;

        &>div {
          height: 100%;
        }

        &>div.no-data {
          background: #F6F9FC;
          font-weight: 600;
          height: calc(100% - 20px);
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
        }
      }
    }
  }
}


.failed-email-security {
  .content {
    .security-data {
      border-bottom: 1px dashed #E1E3EA;
      padding: 10px 0;

      &.index-0 {
        padding-top: 0;
      }

      &.index-2 {
        border-bottom: none;
      }

      & .failed {
        color: #F65C5C;
      }

      & .success {
        color: #12AFB8;
      }
    }
  }
}

.title-sec {
  & > .keyInsight-count {
    border-radius: 100%;
    background: #B6E7F2;
    font-weight: 600;
  }
}

.start-easm-btn {
  width: 160px;
  margin: 0 auto;
}

.easm-init-image {
  display: flex;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
  height: 200px;
  background: #228EBC;
  border-radius: 100%;
  align-items: center;
}

.spf-modal {
  padding: 12px;

  & .note {
    background-color: #E2F6FA;
    border-radius: 10px;
  }
}

.counter-chart-icon {
  display: flex;
  margin-top: 6px;
}

.counter-chart-label {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}